import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import CtaButton from './ctaButton';

const NeedMoreInformation = (props) => {
  const { onCta } = props;

  const handleScheduleConversation = () => {
    onCta();
  };

  return (
    <Box
      sx={{
        opacity: 99.9,
        // display: 'flex',
        // flexDirection: 'column',
        width: { xs:'100%', lg: '50%' },
        height: { xs:'100%', lg: '900px' },
        // flexGrow: 1,
        background: '#E9AD21',
        paddingTop: { xs:'31px', lg: '129px' },
        paddingBottom: { xs:'53px', lg: '156px' },
        paddingLeft: { xs:'26px', lg: '99px' },
        paddingRight: { xs:'26px', lg: '99px' },
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs:'35px', lg: '54px' },
          lineHeight: { xs:'40px', lg: '60px' },
          letterSpacing: '-1.125px',
          color: '#FFFFFF',
          maxWidth: { xs:'220px', sm: '500px', lg: '500px', xl: '750px' },
          paddingBottom: '21px',
        }}
      >
        Need more information? You got it!
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: { xs:'16px', lg: '22px' },
          lineHeight: '30px',
          letterSpacing: '-1.125px',
          color: '#5F6468',
          // width: { xs:'310px', lg: '483px', xl: '600px' },
          paddingBottom: '61px',
        }}
      >
        Thanks to all of you who checked out Bendable at PLA in Portland! We were knocked out by the response. As we field an overwhelming amount of interest, we’re inviting you to be part of our next cohort of Bendable libraries launching in fall 2022. Space is limited!
      </Typography>

      {/* <CtaButton text="Schedule a conversation" backgroundColor="#1B7392" onClick={handleScheduleConversation} /> */}

    </Box>
  );
};

export default NeedMoreInformation;