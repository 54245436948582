import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

import Layout from '../components/layout';
import PrimaryAppBar from '../components/appBar/PrimaryAppBar';

import NeedMoreInformation from '../components/librariesPage/needMoreInformation';
import PhonesWithBendable from '../components/librariesPage/phonesWithBendable';

import Footer from '../components/footer/Footer';
import DemoFollowUpForm from '../components/librariesPage/demoFollowUpForm';
import ContactFormImageBG from '../components/librariesPage/contactFormImageBG';

const FollowUpPage = (props) => {

  return (
    <Layout pageTitle="Bendable for Libraries">
      <PrimaryAppBar instanceName="" backPath="/libraries" />

      <Box id="appBarCompensation" sx={{
        height: { xs: '58px', lg: '90px' },
      }} />

      {/* ROW 1 */}
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        // sx={{
        //   height: { xs: '350px', md: '500px', lg: '900px' },
        // }}
      >
        <NeedMoreInformation />
        <DemoFollowUpForm showTopLine={true} />
      </Stack>

      <Footer />
    </Layout>
  )
}

export default FollowUpPage;