import React from 'react';

import Box from '@mui/material/Box';

import backgroundImage from '../../images/contact_form_2.png';

const ContactFormImageBG = (props) => {

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: '365px', lg: '100%' },
        background: `url(${backgroundImage}) no-repeat center center`,
        // background: `url(https://bendable.s3.us-west-1.amazonaws.com/mg_libraries_poster1.jpg) no-repeat center center`,
        backgroundSize: 'cover',
      }}
    >
      &nbsp;
    </Box>
  );
};

export default ContactFormImageBG;