import React, { useState, useRef } from 'react';

import ContactFormButton from './contactFormButton';
import Box from '@mui/material/Box';
import ContactFormEmailInput from './contactFormEmailInput';
import ContactFormNameInput from './contactFormNameInput';
import DemoFollowUpRadioInput from './demoFollowUpRadioInput';
import FormOtherTextInput from './formOtherTextInput';

const DemoFollowUpFormInputPage = (props) => {
  console.log('ContactFormInputPage props: ', props);
  const [currentName, setCurrentName] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [currentRadio, setCurrentRadio] = useState(null);
  const [currentOther, setCurrentOther] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [disableOther, setDisableOther] = useState(true);


  const handleNameChange = (name) => {
    console.log('contactFormInputPane.handleNameChange() name: ', name);
    setCurrentName(name);
  };

  const handleEmailChange = (email) => {
    console.log('contactFormInputPane.handleEmailChange() email: ', email);
    setCurrentEmail(email);
  };

  const handleRadioChange = (radio) => {
    console.log('contactFormInputPane.handleRadioChange() radio: ', radio);
    setDisableOther(radio !== 'other');
    setCurrentRadio(radio);
  };

  const handleOtherChange = (other) => {
    console.log('contactFormInputPane.handleOtherChange() other: ', other);
    setCurrentOther(other);
  };

  const onSubmitClickHandler = (event) => {
    props.onSubmit(
      {
        name: currentName, 
        email: currentEmail, 
        action: currentRadio,
        other: currentOther,
        source: 'bendable.com/followUp'
      }
    );
  };

  return (
    <Box 
      sx={{
        position: 'relative',
        paddingTop: { xs: '8px', lg: '16px'},
        paddingLeft: { xs: '16px', lg: '100px'},
        paddingRight: { xs: '16px', lg: '100px'},
        // backgroundColor: '#F4615F',
        height: '100%'
      }}
    >

      <ContactFormNameInput onChange={handleNameChange} />
      <ContactFormEmailInput onChange={handleEmailChange} marginBottom="10px" />
      <DemoFollowUpRadioInput onChange={handleRadioChange} />
      <FormOtherTextInput onChange={handleOtherChange} disabled={false && disableOther} />

      <Box 
        sx={{
          position: 'relative',
          marginTop: '40px',
        }}
      >
        <ContactFormButton 
          text="Submit"
          backgroundColor="#95FFEF"
          onClick={onSubmitClickHandler} 
          disabled={!isValid} 
        />
      </Box>
    </Box>
  )
}

export default DemoFollowUpFormInputPage;